'use strict';

import $ from 'jquery';
import Waves from 'node-waves';
import './vendor';
import Slider from './app/slider';
import Promo from './app/slider-promo';
import FilterSidebar from './app/filter-sidebar';
// import AccordionMenu from './app/accordion-menu';
import { SidebarMenu } from './app/sidebar-menu';

$(document).ready(function() {

  /** new scripts */

  new SidebarMenu({
    sidebar: '[data-tag="sidebarRoot"]',
    menu: '[data-tag="sidebarMenu"]',
    trigger: '[data-tag="sidebarToggle"]'
  });
  /** end new scripts */

  new FilterSidebar('.js-filter-sidebar');
  // new AccordionMenu('.js-accordion-menu');

  $('[data-toggle-state]').on('click', function(e) {
    e.stopPropagation();
    const body = $('body');
    if(body.hasClass('show-sidebar')) {
      body.removeClass('show-sidebar');
      $('.nav-toggler .mdi').toggleClass('mdi-menu');
    }
    const element = $(this);
    const className = element.data('toggleState');
    const target = body;
    if (className) {
      if (target.hasClass(className)) {
        target.removeClass(className);
      } else {
        target.addClass(className);
      }
    }
  });

  $('.nav-toggler').click(function() {
    const body = $('body');
    if(body.hasClass('header-mobile-toggle')) {
      body.removeClass('header-mobile-toggle');
    }
  });

  new Slider('.js-catalog-slider');
  new Promo('.js-promo-slider');

  // This is for the top header part and sidebar part
  var set = function() {
    var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
    var topOffset = 0;
    if (width < 1170) {
      $('body').addClass('mini-sidebar');
      $('.navbar-brand span').hide();
      $('.navbar-brand .logo-v').show();
      $('.sidebartoggler i').addClass('mdi-menu');
    } else {
      $('body').removeClass('mini-sidebar');
      $('.navbar-brand span').show();
      $('.navbar-brand .logo-v').hide();
    }

    var height = ((window.innerHeight > 0) ? window.innerHeight : this.screen.height) - 1;
    height = height - topOffset;
    if (height < 1) height = 1;
    if (height > topOffset) {
      $('.page-wrapper').css('min-height', (height) + 'px');
    }

  };
  $(window).ready(set);
  $(window).on('resize', set);

  // Theme options
  $('.sidebartoggler').on('click', function() {

    if ($('body').hasClass('mini-sidebar')) {
      $('body').trigger('resize');
      $('body').removeClass('mini-sidebar');
      $('.navbar-brand span').show();
      $('.navbar-brand .logo-v').hide();
    } else {
      $('body').trigger('resize');
      $('body').addClass('mini-sidebar');
      $('.navbar-brand span').hide();
      $('.navbar-brand .logo-v').show();
    }
  });

  // this is for close icon when navigation open in mobile view
  $('.nav-toggler').click(function() {
    $('body').toggleClass('show-sidebar');
    $('.nav-toggler i').toggleClass('mdi-menu');
    $('.nav-toggler i').addClass('mdi-close');
  });

  $(function() {
    $('#sidebarnav');
    $('#sidebarnav li a').each(function() {
      var $t = $(this);
      if ($t.next().is('ul')) {
        $t.addClass('has-arrow');
      }
    });
  });

  //tooltip
  $(function() {
    $('[data-toggle="tooltip"]').tooltip();
  });

  //Popover
  $(function() {
    $('[data-toggle="popover"]').popover();
  });

  // Sidebarmenu
  $(function() {
    $('#sidebarnav').AdminMenu();
  });

  // Resize all elements
  $('body').trigger('resize');

  // Collapsable cards
  $('a[data-action="collapse"]').on('click', function(e) {
    e.preventDefault();
    $(this).closest('.card').find('[data-action="collapse"] i').toggleClass('mdi-minus mdi-plus');
    $(this).closest('.card').children('.card-body').collapse('toggle');
  });

  // Toggle fullscreen
  $('a[data-action="expand"]').on('click', function(e) {
    e.preventDefault();
    $(this).closest('.card').find('[data-action="expand"] i').toggleClass('mdi-arrow-expand mdi-arrow-compress');
    $(this).closest('.card').toggleClass('card-fullscreen');
  });

  // Close Card
  $('a[data-action="close"]').on('click', function() {
    $(this).closest('.card').removeClass().slideUp('fast');
  });

  // запрет закрытия bootstrap dropdown
  $('.dropdown-menu').on('click', function(e) {
    if ($(this).hasClass('dropdown-menu--form')) {
      e.stopPropagation();
    }
  });

  Waves.init();
  $("input[type='tel']").mask('+7 (999) 999-99-99');
  $('.js-select').cSelect();

  $('.scrollbar-macosx').scrollbar();


  // popup picture
  $('.popup-picture').magnificPopup({
    type: 'image',
    closeMarkup: '<button title="%title%" class="mfp-close"><svg class="svg-icon icon-close"><use xlink:href="#close"></use></svg></button>',
    closeOnContentClick: true,
    closeBtnInside: false,
    fixedContentPos: true,
    mainClass: 'mfp-with-zoom mfp-fade'
  });

  $('.popup-gallery').magnificPopup({
    delegate: 'a',
    type: 'image',
    closeMarkup: '<button title="%title%" class="mfp-close"><svg class="svg-icon icon-close"><use xlink:href="#close"></use></svg></button>',
    tLoading: 'Загрузка изображения #%curr%...',
    mainClass: 'mfp-with-zoom mfp-fade',
    closeOnContentClick: true,
    closeBtnInside: false,
    fixedContentPos: true,
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 2],
      arrowMarkup: '<span title="%title%" class="mfp-arrow mfp-arrow--%dir%"><svg class="svg-icon icon-chevron-right"><use xlink:href="#chevron-%dir%"></use></svg></span>',
      tPrev: 'Назад',
      tNext: 'Вперед',
      tCounter: ''
    },
    image: {
      tError: '<a href="%url%">Изображение #%curr%</a> не может быть загружено.'
    }
  });

});

