import {debounce} from 'debounce';

export class SidebarMenu {
  constructor({ sidebar, menu, trigger}) {
    this.sidebar = document.querySelector(sidebar);
    this.menu = document.querySelector(menu);
    this.trigger = document.querySelector(trigger);
    this.trigger.onclick = this._onToggleClick.bind(this);
    this.menu.onclick = this._onMenuHandler.bind(this);
    this._renderItems();
    this.sidebarResize = debounce(this._resize.bind(this), 200);
    window.onresize = this.sidebarResize;
  }

  _open() {
    this._toggle(true);
  }

  _close() {
    this._toggle(false);
  }

  _toggle(show) {
    this.trigger.classList.toggle('is-active', show);
    this.sidebar.classList.toggle('is-mini', show);
  }

  _onToggleClick(e) {
    e.preventDefault();
    if(this.trigger.classList.contains('is-active')) {
      this._close();
    } else {
      this._open();
    }
  }

  _onMenuHandler(e) {
    const item = e.target.closest('li');
    if(item && item.classList.contains('has-submenu')) {
      e.preventDefault();
      item.classList.toggle('is-open');
    }
  }

  _renderItems() {
    const items = this.menu.querySelectorAll('li');
    items.forEach(el => {
      const isSubItems = !!el.querySelector('ul');
      if (isSubItems) {
        const arrow = document.createElement('span');
        arrow.className = 'menu-item__arrow';
        el.querySelector('a').append(arrow);
        el.classList.add('has-submenu');
      }
    });
  }

  _resize() {
    console.log(1);
    if (window.innerWidth < 1260) {
      this._toggle(true);
    }
  }
}
