import Swiper from 'swiper';

export default class Slider {
  constructor(selector) {
    this.selector = selector;
    this.slider = document.querySelector(selector);
    this.thumbs = document.querySelector('.js-slider-thumbs');

    if (this.slider) {

      let thumbnails = new Swiper(this.thumbs, {
        spaceBetween: 10,
        slidesPerView: 2,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        navigation: {
          nextEl: '.js-slider-thumbs-next',
          prevEl: '.js-slider-thumbs-prev',
        },
        breakpoints: {
          576: {
            slidesPerView: 3
          },
          768: {
            slidesPerView: 4
          },
          1420: {
            slidesPerView: 2
          },
          1800: {
            slidesPerView: 3
          }
        }
      });

      new Swiper(this.selector, {
        spaceBetween: 0,
        preloadImages: true,
        loop: false,
        slidesPerView: 1,
        effect: 'fade',
        thumbs: {
          swiper: thumbnails
        }
      });

    }
  }
}
